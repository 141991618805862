<template>
  <div>
    <Navbar />
    <TravelsAndTourHeroSection />
    <OurMission />
    <TicketExcellence />
    <InnovativeTechnology />
    <InternationalConnectivity />
    <WhyChooseTripleT />
    <JoinTheTripleTExperience />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import TravelsAndTourHeroSection from "@/components/sections/travelsAndTourViewSection/TravelsAndTourHeroSection.vue";
import TicketExcellence from "@/components/sections/travelsAndTourViewSection/TicketExcellence.vue";
import InnovativeTechnology from "@/components/sections/travelsAndTourViewSection/InnovativeTechnology.vue";
import InternationalConnectivity from "@/components/sections/travelsAndTourViewSection/InternationalConnectivity.vue";
import OurMission from "@/components/sections/travelsAndTourViewSection/OurMission.vue";
import WhyChooseTripleT from "@/components/sections/travelsAndTourViewSection/WhyChooseTripleT.vue";
import JoinTheTripleTExperience from "@/components/sections/travelsAndTourViewSection/JoinTheTripleTExperience.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "TravelsAndTourView",
};
</script>
