<template>
  <div class="bg-Linen">
    <HeaderReverse
      title_line_one="Personalise Patient "
      title_line_two="Experience"
      paragraph="With Turbomedics, the patient journey is prioritized. The platform offers a user-friendly interface, ensuring a seamless experience from appointment booking to follow-up care."
      text="Learn more"
      href="https://www.turbomedics.com/"
      backgroundColor="bg-InternationalOrange"
      imageSrc="personalise_patient_img.png"
    />
  </div>
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "PersonalisePatient",
};
</script>
