<template>
  <div
    :class="[
      height_and_padding,
      'container mx-auto flex flex-col space-y-4 md:space-y-0 md:flex-row pt-10 pb-1 md:py-16 xl:py-24 overflow-hidden relative z-10',
    ]"
  >
    <div
      class="w-full flex flex-col items-center md:items-start md:w-[60%] space-y-4 md:space-y-8 pb-8 md:pb-0"
    >
      <span
        :class="[
          Website_name_color,
          Website_name_bg_color,
          'px-4 py-2 rounded-3xl font-urbanist font-medium text-sm md:text-lg uppercase',
        ]"
        >{{ Website_name }}</span
      >
      <h1
        :class="[
          text_size,
          'text-center md:text-left font-duplicate-sans-medium text-3xl md:max-w-[35rem] flex flex-col animate__animated animate__fadeInUp',
        ]"
      >
        <span :class="[title_line_one_color]">{{ title_line_one }}</span>
        <span :class="[title_line_two_color]">{{ title_line_two }}</span>
        <span :class="[italic, title_line_three_color]">{{
          title_line_three
        }}</span>
      </h1>
      <div class="block md:hidden w-full md:w-[40%] h-full">
        <img
          class="w-full relative -left-8 md:scale-150"
          :src="require(`../../../assets/images/${imageSrc}`)"
          alt=""
        />
      </div>
      <p
        class="text-center md:text-left max-w-[26rem] text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-normal"
      >
        {{ paragraph }}
      </p>
      <div>
        <CustomButton
          :text="text"
          :backgroundColor="backgroundColor"
          :href="href"
        />
      </div>
    </div>
    <div class="hidden md:block w-full md:w-[40%] h-full">
      <img
        class="w-full relative -left-8 md:scale-150"
        :src="require(`../../../assets/images/${imageSrc}`)"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import CustomButton from "@/components/CustomButton.vue";
import { defineProps, reactive } from "vue";

const props = defineProps([
  "title",
  "paragraph",
  "text",
  "backgroundColor",
  "imageSrc",
  "Website_name",
  "Website_name_color",
  "Website_name_bg_color",
  "title_line_one",
  "title_line_two",
  "title_line_three",
  "title_line_one_color",
  "title_line_two_color",
  "title_line_three_color",
  "href",
  "text_size",
  "italic",
  "height_and_padding",
]);
</script>

<script>
export default {
  name: "Header",
};
</script>
