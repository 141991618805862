<template>
  <div>
    <Navbar />
    <AboutHeroSection />
    <OurJourney />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import AboutHeroSection from "@/components/sections/aboutSection/AboutHeroSection.vue";
import OurJourney from "@/components/sections/aboutSection/OurJourney.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "AboutView",
};
</script>
