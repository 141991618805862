<template>
  <div class="py-10 md:py-44">
    <div class="container mx-auto space-y-5 md:space-y-10">
      <h1
        class="text-DeepGreen text-center font-duplicate-sans-medium text-3xl md:text-[56px] md:leading-[3.5rem] flex flex-col"
      >
        Join the Triple T Experience
      </h1>
      <div
        class="flex flex-col justify-center items-center space-y-2.5 md:space-y-5"
      >
        <p
          class="md:max-w-6xl text-center text-lg md:text-[24px] md:leading-[2.2rem] text-DavyGrey font-urbanist font-light"
        >
          Turbham Travels and Tours invites you to join a community that values
          your travel aspirations. Whether you are a globetrotter or a local
          explorer, we promise to make every journey memorable, every
          destination reachable, and every moment cherished.
        </p>
        <p
          class="md:max-w-6xl text-center text-lg md:text-[24px] md:leading-[2.2rem] text-DavyGrey font-urbanist font-light"
        >
          Your adventure begins with Turbham - where technology meets travel in
          perfect harmony.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinTheTripleTExperience",
};
</script>
