<template>
  <div class="py-8 md:py-20">
    <div
      class="container mx-auto space-y-5 md:space-y-10 flex flex-col items-center justify-center text-center mb-10 md:mb-20"
    >
      <h2 class="text-Cinder font-duplicate-sans-medium text-3xl md:text-5xl">
        We are pioneering technology company dedicated to driving positive
        change across diverse sectors, including healthcare, agriculture, BPO
        services, aviation, and finance.
      </h2>
      <p class="text-lg md:text-2xl font font-urbanist font-light md:max-w-4xl">
        We proudly house a non-profit organization, embodying our unwavering
        commitment to making a meaningful impact on the lives of those in need.
      </p>
      <p class="text-lg md:text-2xl font font-urbanist font-light md:max-w-4xl">
        Our specialized branches stand as beacons of innovation and excellence
        in their respective fields. Alongside these ventures, our NGO serves as
        a steadfast source of hope and support for individuals facing challenges
        in their lives.
      </p>
    </div>
    <div class="mission_and_value_bg py-24">
      <div
        class="container mx-auto flex flex-col-reverse items-center space-y-4 md:space-y-0 md:flex-row md:justify-between"
      >
        <div class="w-full md:w-[55%]">
          <p
            class="text-center md:text-left text-white text-lg md:text-[22px] md:leading-[2.2rem] font font-urbanist font-light md:max-w-lg"
          >
            At Turbham Technologies, we firmly believe in the transformative power of
            technology. Our non-profit arm embodies this belief, extending a
            compassionate hand to those who need it most. With unwavering
            commitment to excellence, we are not only pioneers in technology but
            also champions of humanitarian efforts.
          </p>
        </div>
        <div
          class="w-full md:w-[45%] flex items-center justify-center relative -left-8 md:-left-0"
        >
          <img
            src="../../../assets/images/about_us_imgs.png"
            class="scale-125"
            alt=""
          />
        </div>
      </div>
    </div>

    <div
      class="container mx-auto space-y-5 md:space-y-10 flex flex-col items-center justify-center text-center mb-10 md:mb-20 pt-8 md:pt-32"
    >
      <p
        class="text-lg md:text-[22px] md:leading-[2.2rem] font font-urbanist font-light md:max-w-4xl"
      >
        We are strong advocates of technology's transformative
        impact. Through our non-profit division, we channel this conviction into
        meaningful support for those in need. Our dedication to excellence
        drives us to lead in technological innovation while also advancing
        humanitarian causes with compassion.
      </p>
      <h2
        class="text-Cinder font-duplicate-sans-medium text-3xl md:text-5xl md:max-w-4xl"
      >
        <span>Join us in our </span
        ><span class="text-InternationalOrange"
          >collective mission to redefine what's possible</span
        >
        <span
          >, not only in technology but also in the realm of social impact.
          Together, we can forge a brighter future for all.</span
        >
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionAndValueBody",
};
</script>
