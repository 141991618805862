<template>
  <div>
    <div class="space-y-2 md:space-y-8">
      <img :src="whatWeOfferInfo.icon" class="" alt="" />
      <div class="space-y-2.5">
        <h3 class="text-xl md:text-2xl text-white font-urbanist font-medium">
          {{ whatWeOfferInfo.title }}
        </h3>
        <p class="max-w-xs md:max-w-sm text-sm md:text-base text-white font-urbanist">
          {{ whatWeOfferInfo.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive } from "vue";

const props = defineProps(["whatWeOfferInfo"]);
</script>

<script>
export default {
  name: "WhatWeOfferCard",
};
</script>
