<template>
  <div class="bg-DawnPink py-16 md:py-32">
    <div class="container mx-auto space-y-10">
      <h3 class="text-2xl md:text-4xl font-duplicate-sans-medium">
        Why Choose Turbham Travels and Tours?
      </h3>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 md:gap-x-16"
      >
        <div
          v-for="(whyChooseTripleTInfo, index) in whyChooseTripleTInfos"
          :key="index"
        >
          <WhyChooseTripleTCard :whyChooseTripleTInfo="whyChooseTripleTInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import WhyChooseTripleTCard from "@/components/primitives/cards/WhyChooseTripleTCard.vue";
import whyChooseTripleTInfos from "@/mockdata/whyChooseTripleTInfos";
</script>

<script>
export default {
  name: "WhyChooseTripleT",
};
</script>
