<template>
  <div>
    <Navbar />
    <TurbhamFoundationHeroSection />
    <Overview />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import TurbhamFoundationHeroSection from "@/components/sections/TurbhamFoundationSection/TurbhamFoundationHeroSection.vue";
import Overview from "@/components/sections/TurbhamFoundationSection/Overview.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "TurbhamFoundationView",
};
</script>
