import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import MissionAndValue from "../views/MissionAndValue.vue";
import TurbomedicsView from "../views/TurbomedicsView.vue";
import TurbhamFarmView from "../views/TurbhamFarmView.vue";
import TurbhamFoundationView from "../views/TurbhamFoundationView.vue";
import TravelsAndTourView from "../views/TravelsAndTourView.vue";
import TurbhamLogistics from "../views/TurbhamLogistics.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Innovating Tomorrow, Today | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/about-us",
    name: "about_us",
    component: AboutView,
    meta: {
      title: "About Us | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/mission-and-values",
    name: "about",
    component: MissionAndValue,
    meta: {
      title: "Mission and Values | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/turbomedics",
    name: "turbomedics",
    component: TurbomedicsView,
    meta: {
      title: "Turbomedics | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/farm",
    name: "farm",
    component: TurbhamFarmView,
    meta: {
      title: "Turbham Farm | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/foundation",
    name: "foundation",
    component: TurbhamFoundationView,
    meta: {
      title: "Turbham Foundation | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/travels-and-tour",
    name: "travels_and_tour",
    component: TravelsAndTourView,
    meta: {
      title: "Turbham Tranvels and Tour | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/logistics",
    name: "logistics",
    component: TurbhamLogistics,
    meta: {
      title: "Turbham Logistics | Turbham",
      toTop: true,
      smoothScroll: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
