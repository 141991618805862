<template>
  <div class="bg-Linen">
    <HeaderReverse
      title_line_one="Overview"
      paragraph="The Turbham Foundation is a testament to our unwavering dedication to social progress. Through the seamless integration of technology and compassion, we are making a positive impact on a global scale."
      imageSrc="overview.png"
      text="Learn more"
      href="https://turbhamfoundation.org/"
      backgroundColor="bg-InternationalOrange"
    />
  </div>
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "Overview",
};
</script>
