export const sidebarLearnItems = [
  {
    title: "Turbomedics",
    link: "/turbomedics",
  },
  {
    title: "Turbham Farms",
    link: "/farm",
  },
  {
    title: "Turbham Foundation",
    link: "/foundation",
  },
  {
    title: "Lorem Support",
    link: "/logistics",
  },
  {
    title: "Turbham Travels and Tour",
    link: "/travels-and-tour",
  },
];
