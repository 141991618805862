<template>
  <div class="bg-Magnolia">
    <HeaderReverse
      title_line_one="Cutting Edge Hardware"
      title_line_two="Integration"
      paragraph="Turbomedics seamlessly incorporates state-of-the-art hardware solutions into its platform. This ensures that healthcare providers have access to the latest tools for delivering precise and efficient medical services."
      text="Learn more"
      href="https://www.turbomedics.com/"
      backgroundColor="bg-InternationalOrange"
      imageSrc="cutting_edge_hardware_integration_img.png"
    />
  </div>
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "CuttingEdgeHardwareIntegration",
};
</script>
