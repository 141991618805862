<template>
  <div>
    <div class="w-full h-full relative">
      <img
        :src="ourTechnologyInfo.image"
        class="w-full h-full rounded-2xl"
        alt=""
      />
      <div class="flex justify-center">
        <router-link
          :to="ourTechnologyInfo.link"
          class="'w-[80%] h-40 md:h-56 rounded-2xl absolute bottom-2 md:bottom-12 bg-white/[.2] backdrop-blur-md border border-transparent hover:border hover:border-ResolutionBlue transition-all duration-700 px-3 md:px-8 py-2 md:py-6 space-y-1 md:space-y-2',"
        >
          <h5
            :class="[
              ourTechnologyInfo.title_color,
              'font-urbanist font-medium text-[8px] md:text-xs',
            ]"
          >
            {{ ourTechnologyInfo.title }}
          </h5>
          <h2
            class="font-duplicate-sans-medium flex flex-col -space-y-2 md:-space-y-1 text-lg md:text-3xl"
          >
            <span :class="[ourTechnologyInfo.heading_line_one_color]">{{
              ourTechnologyInfo.heading_line_one
            }}</span>
            <span :class="[ourTechnologyInfo.heading_line_two_color]">{{
              ourTechnologyInfo.heading_line_two
            }}</span>
          </h2>
          <p
            class="font-urbanist font-light text-[10px] md:text-sm max-w-[18rem]"
          >
            {{ ourTechnologyInfo.description }}
          </p>
          <div>
            <router-link
              :to="ourTechnologyInfo.link"
              class="flex items-center space-x-3 font-urbanist font-light"
            >
              <span class="btn-content text-[10px] md:text-xs">Learn more</span>
              <span class="">
                <img
                  src="../../../assets/icons/learn_more_icon.svg"
                  class="w-2 h-2 md:w-2.5 md:h-2.5"
                  alt=""
              /></span>
            </router-link>
          </div>
        </router-link>
      </div>
      <!-- <img :src="ourTechnologyInfo.icon" class="" alt="" />
      <div class="space-y-2.5">
        <h3 class="text-xl md:text-2xl font-urbanist font-medium">
          {{ ourTechnologyInfo.title }}
        </h3>
        <p class="max-w-xs md:max-w-sm text-sm md:text-base font-urbanist">
          {{ ourTechnologyInfo.description }}
        </p>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, watchEffect, ref } from "vue";
import CustomButton from "@/components/CustomButton.vue";

const props = defineProps(["ourTechnologyInfo"]);

// const technologyInfo = ref(props.ourTechnologyInfo);

// console.log("technologyInfo", technologyInfo.value);

// const isHovered = ref(false);

// const scaleClass = ref("");

// watchEffect(() => {
//   scaleClass.value = isHovered.value ? technologyInfo.value?.border_color : "";
// });
</script>

<script>
export default {
  name: "OurTechnologyCard",
};
</script>
