<template>
  <div>
    <Navbar />
    <HomePageHeader />
    <OurOffer />
    <OurTechnology />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import HomePageHeader from "@/components/sections/homeSection/HomePageHeader.vue";
import OurOffer from "@/components/sections/homeSection/OurOffer.vue";
import OurTechnology from "@/components/sections/homeSection/OurTechnology.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "HomeView",
};
</script>
