import icon from "../assets/icons/driven_mission_icon.svg";

const ourTechnologyInfos = [
  {
    icon: icon,
    title: "Nurture Nature:",
    description:
      "We cultivate and care for our lands with the utmost respect for nature's delicate balance.",
  },
  {
    icon: icon,
    title: "Innovate with Integrity:",
    description:
      "Our practices are transparent, ethical, and aligned with the highest industry standards.",
  },
  {
    icon: icon,
    title: "Empower Farmers:",
    description:
      "Our practices minimize environmental impact, conserve natural resources, and promote biodiversity.",
  },
  {
    icon: icon,
    title: "Champion Sustainability:",
    description:
      "Our practices minimize environmental impact, conserve natural resources, and promote biodiversity.",
  },
  {
    icon: icon,
    title: "Nourish Communities:",
    description:
      "We provide nutritious, high-quality produce that sustains communities near and far.",
  },
  {
    icon: icon,
    title: "Cultivate Excellence:",
    description:
      "Excellence is our standard in everything we do, from precision farming techniques to quality control.",
  },
  {
    icon: icon,
    title: "Foster Collaboration:",
    description:
      "We value partnerships with every stakeholder in the Agricultural sector to create a more sustainable and prosperous agricultural ecosystem.",
  },
];

export default ourTechnologyInfos;
