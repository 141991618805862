<template>
  <Header
    Website_name="TURBHAM TRAVELS"
    title_line_one="We transcend the"
    title_line_two="ordinary to redefine"
    title_line_three="your travel experience"
    paragraph="As a distinguished player in the aviation and tourism sector, we take pride in seamlessly integrating innovative technology with unparalleled service to bring you a travel solution that is not only classy but also remarkably affordable."
    imageSrc="travels_and_tour_hero_section_img.png"
    Website_name_color="text-DeepGreen"
    Website_name_bg_color="bg-OffGreen"
    title_line_one_color="text-black"
    title_line_two_color="text-black"
    title_line_three_color="text-DeepGreen"
    text_size="md:text-[56px] md:leading-[3.5rem]"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TravelsAndTourHeroSection",
};
</script>
