<template>
  <HeaderReverse
    title_line_one="International Connectivity"
    paragraph="Turbham Travels and Tours is your gateway to the world. We provide international ticketing services, connecting you to a plethora of destinations with a touch of sophistication."
    imageSrc="international_conectivity_img.png"
  />
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "InternationalConnectivity",
};
</script>
