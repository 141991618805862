<template>
  <div>
    <div class="group inline-block relative z-50">
      <button class="outline-none focus:outline-none flex items-center">
        <span class="pr-2 flex-1 text-lg">Our Technologies</span>
        <span>
          <svg
            class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </span>
      </button>
      <div class="">
        <ul
          class="pt-4 transform scale-0 group-hover:scale-100 absolute transition duration-150 space-y-2 py-3 ease-in-out origin-top xl:w-64 z-50"
        >
          <div class="rounded-lg px-4 py-6 space-y-4 bg-white shadow-2xl">
            <div class="w-full hover:bg-[#eee] py-1 px-1.5 rounded-md">
              <a
                href="/turbomedics"
                class="flex items-center space-x-3 text-sm"
              >
                <img src="../../../assets/icons/turbomedi_ii.svg" alt="" />
                <div class="flex flex-col">
                  <span class="text-sm">TurboMedics</span>
                  <span class="text-[10px]">Telemedicine for everyone</span>
                </div>
              </a>
            </div>
            <div class="w-full hover:bg-[#eee] py-1 px-1.5 rounded-md">
              <a href="/farm" class="flex items-center space-x-3 text-sm">
                <img src="../../../assets/icons/turbham_farm_ii.svg" alt="" />
                <div class="flex flex-col">
                  <span class="text-sm">Turbham Farms</span>
                  <span class="text-[10px]"
                    >Grow and thrive with innovation</span
                  >
                </div>
              </a>
            </div>

            <div class="w-full hover:bg-[#eee] py-1 px-1.5 rounded-md">
              <a
                href="/travels-and-tour"
                class="flex items-center space-x-3 text-sm"
              >
                <img src="../../../assets/icons/triple_t_ii.svg" alt="" />
                <div class="flex flex-col">
                  <span class="text-sm">Turbham Travels</span>
                  <span class="text-[10px]"
                    >Get access to the best ticket prices</span
                  >
                </div>
              </a>
            </div>
            <div class="w-full hover:bg-[#eee] py-1 pr-1.5 rounded-md">
              <a href="/logistics" class="flex items-center space-x-3 text-sm">
                <img
                  src="../../../assets/icons/lorem_support_favicon.svg"
                  class="w-12 scale-125 rounded-full"
                  alt=""
                />
                <div class="flex flex-col">
                  <span class="text-sm">Lorem Support</span>
                  <span class="text-[10px]"
                    >We take your goals a step forward</span
                  >
                </div>
              </a>
            </div>
            <div class="w-full hover:bg-[#eee] py-1 px-1.5 rounded-md">
              <a href="/foundation" class="flex items-center space-x-3 text-sm">
                <img src="../../../assets/icons/turbham_foun_ii.svg" alt="" />
                <div class="flex flex-col">
                  <span class="text-sm">Turbham Foundation</span>
                  <span class="text-[10px]">A commitment to community</span>
                </div>
              </a>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTechnologiesDropdown",
};
</script>
