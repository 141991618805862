<template>
  <div class="bg-Linen">
    <HeaderReverse
      title_line_one="Robust Data "
      title_line_two="Collection Tools"
      paragraph="Turbomedics is equipped with powerful data collection tools that enable healthcare professionals to gather and analyze patient information effectively. This empowers them to make well-informed decisions and provide the best possible care."
      text="Learn more"
      href="https://www.turbomedics.com/"
      backgroundColor="bg-InternationalOrange"
      imageSrc="robust_data_collection_tools.png"
    />
  </div>
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "RobustDataCollectionTools",
};
</script>
