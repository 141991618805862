<template>
  <div>
    <Navbar />
    <TurbhamLogisticsHeroSection />
    <LogisticsBody />
    <StepIntoInnovation />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import TurbhamLogisticsHeroSection from "@/components/sections/turbhamLogisticsSection/TurbhamLogisticsHeroSection.vue";
import LogisticsBody from "@/components/sections/turbhamLogisticsSection/LogisticsBody.vue";
import StepIntoInnovation from "@/components/sections/turbhamLogisticsSection/StepIntoInnovation.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "TurbhamLogistics",
};
</script>
