<template>
  <div
    class="container mx-auto flex flex-col items-center space-y-4 md:space-y-0 md:flex-row py-16 md:py-20 overflow-hidden"
  >
    <h1
      class="md:hidden text-center md:text-left font-duplicate-sans-medium text-3xl md:text-7xl md:max-w-[35rem] flex flex-col"
    >
      <span :class="[title_line_one_color]">{{ title_line_one }}</span>
      <span :class="[title_line_two_color]">{{ title_line_two }}</span>
      <span :class="[title_line_three_color]">{{ title_line_three }}</span>
    </h1>
    <div class="w-full md:w-[55%] h-full">
      <img
        class="w-full scale-75 relative -left-8 md:-left-32"
        :src="require(`../../../assets/images/${imageSrc}`)"
        alt=""
      />
    </div>
    <div
      class="w-full flex flex-col items-center md:items-start md:w-[45%] space-y-4 md:space-y-12"
    >
      <span
        :class="[
          Website_name_color,
          Website_name_bg_color,
          'px-4 py-2 rounded-3xl font-urbanist font-medium text-[10px] md:text-xs uppercase',
        ]"
        >{{ Website_name }}</span
      >
      <h1
        :class="[
          'hidden text-center md:text-left font-duplicate-sans-medium text-3xl md:text-5xl md:max-w-[35rem] md:flex flex-col',
        ]"
      >
        <span :class="[title_line_one_color]">{{ title_line_one }}</span>
        <span :class="[title_line_two_color]">{{ title_line_two }}</span>
        <span :class="[title_line_three_color]">{{ title_line_three }}</span>
      </h1>
      <p
        class="text-center md:text-left flex flex-col space-y-4 max-w-[26rem] text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-extralight"
      >
        {{ paragraph }}
      </p>
      <div>
        <CustomButton
          :text="text"
          :backgroundColor="backgroundColor"
          :href="href"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CustomButton from "@/components/CustomButton.vue";
import { defineProps, reactive } from "vue";

const props = defineProps([
  "title",
  "paragraph",
  "text",
  "backgroundColor",
  "imageSrc",
  "Website_name",
  "Website_name_color",
  "Website_name_bg_color",
  "title_line_one",
  "title_line_two",
  "title_line_three",
  "title_line_one_color",
  "title_line_two_color",
  "title_line_three_color",
  "href",
]);
</script>

<script>
export default {
  name: "HeaderReverse",
};
</script>
