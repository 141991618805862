<template>
  <div>
    <Navbar />
    <TurbhamFarmHeroSection />
    <TurbhamFarmBody />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import TurbhamFarmHeroSection from "@/components/sections/turbhamFarmSection/TurbhamFarmHeroSection.vue";
import TurbhamFarmBody from "@/components/sections/turbhamFarmSection/TurbhamFarmBody.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "TurbhamFoundationView",
};
</script>
