<template>
  <Header
    title_line_one="Security and Compliance "
    paragraph="We uphold the highest standards of security for patient data. Turbomedics adheres to industry-leading protocols to safeguard sensitive information, providing peace of mind for both patients and providers."
    text="Learn more"
    href="https://www.turbomedics.com/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="security_and_compliance.png"
    text_size="md:text-5xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "SecurityAndCompliance",
};
</script>
