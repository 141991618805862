<template>
  <Header
    Website_name="TURBHAM FOUNDATION"
    title_line_one="A Commitment"
    title_line_two="to Community"
    paragraph="In the heart of Turbham Foundation lies a force that transcends boundaries, where the symphony of technology and compassion orchestrates a future illuminated by promise."
    text="Get started today"
    href="https://turbhamfoundation.org/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="turbomedics_foundation_hero_section_img.png"
    Website_name_color="text-HalloweenOrange"
    Website_name_bg_color="bg-Linen"
    title_line_one_color="text-black"
    title_line_two_color="text-HalloweenOrange"
    text_size="md:text-7xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TurbhamFoundationHeroSection",
};
</script>
