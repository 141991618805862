<template>
  <div class="py-8 md:py-20">
    <div class="bg-OffGreen py-24">
      <div class="container mx-auto space-y-5 md:space-y-10">
        <h3 class="font-duplicate-sans-medium text-3xl md:text-5xl">
          We are driven by a mission to:
        </h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div
            v-for="(drivenMissionInfo, index) in drivenMissionInfos"
            :key="index"
          >
            <DrivenMissionCard :drivenMissionInfo="drivenMissionInfo" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="container mx-auto space-y-5 md:space-y-10 flex flex-col items-center justify-center text-center mb-10 md:mb-20 pt-5 md:pt-12"
    >
      <h2 class="text-Cinder font-duplicate-sans-medium text-3xl md:text-5xl flex flex-col">
        <span>Our Commitment: </span>
        <span class="italic text-DeepGreen"
          >Contributing to Collective Survival</span
        >
      </h2>
      <p class="text-lg md:text-2xl font font-urbanist font-light md:max-w-4xl">
        "Recognizing the pivotal role of agriculture in sustaining humanity,
        Turbham Farms is deeply committed to this mission. Our substantial
        investments in both plant and animal husbandry underscores this
        dedication. Leveraging cutting-edge technology, sustainable practices,
        and community engagement, we are steadfast in nurturing a legacy of
        growth and prosperity for future generations.
      </p>
      <p class="text-lg md:text-2xl font font-urbanist font-light md:max-w-4xl">
        In our pursuit, we actively innovate and forge partnerships with
        agriculture stakeholders worldwide. Through our initiatives like (PAAS)
        Planting As a Service and (OAAS) Offtaking As A Service , we empower
        farmers to increase their yield. This approach embodies our
        straightforward yet sophisticated approach to advancing agriculture.
      </p>
    </div>
  </div>
</template>

<script setup>
import drivenMissionInfos from "@/mockdata/drivenMissionInfos";
import DrivenMissionCard from "@/components/primitives/cards/DrivenMissionCard.vue";
</script>

<script>
export default {
  name: "TurbhamFarmBody",
};
</script>
