<template>
  <HeaderReverse
    title_line_one="Ticketing Excellence"
    paragraph="Experience the epitome of ticketing services wth Turbham. We cater to both local and international destinations, ensuring that your journey is not just a means of reaching a destination but a delightful adventure from start to finish."
    imageSrc="ticket_excellence_img.png"
    text_size="md:text-5xl"

  />
</template>
<script setup>
import HeaderReverse from "@/components/primitives/headers/HeaderReverse.vue";
</script>

<script>
export default {
  name: "TicketExcellence",
};
</script>
