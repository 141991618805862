<template>
  <Sidebar />
  <router-view />
</template>

<script>
import { ref, provide } from "vue";
import Sidebar from "@/components/connected/Sidbar.vue";

export default {
  name: "App",
  components: {
    Sidebar,
  },
  setup() {
    const showSideBar = ref(false);

    const openSidebar = () => {
      showSideBar.value = true;
    };

    const closeSidebar = () => {
      showSideBar.value = false;
    };

    provide("showSidebar", showSideBar);
    provide("openSidebar", openSidebar);
    provide("closeSidebar", closeSidebar);

    return {};
  },
};
</script>
