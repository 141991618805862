<template>
  <div class="w-full">
    <img
      :src="whyChooseTripleTInfo.image"
      class="rounded-t-2xl w-full h-full"
      alt=""
    />
    <div class="bg-white rounded-b-2xl space-y-2 px-8 py-5 md:h-48">
      <h4 class="text-lg md:text-2xl font-duplicate-sans-medium">
        {{ whyChooseTripleTInfo.title }}
      </h4>
      <p class="font-urbanist font-extralight text-xs">{{ whyChooseTripleTInfo.description }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import CustomButton from "@/components/CustomButton.vue";

const props = defineProps(["whyChooseTripleTInfo"]);
</script>

<script>
export default {
  name: "WhyChooseTripleTCard",
};
</script>
