<template>
  <div>
    <Navbar />
    <MissionAndValueBody />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import MissionAndValueBody from "@/components/sections/MissionAndValueSection/MissionAndValueBody.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "MissionAndValue",
};
</script>
