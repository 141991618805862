import image_1 from "../assets/images/black_woman.png";
import image_2 from "../assets/images/black_man.png";
import image_3 from "../assets/images/delivery_guy.png";
import image_4 from "../assets/images/man_and_woman.png";

const ourTechnologyInfos = [
  {
    image: image_1,
    title: "TURBOMEDICS",
    heading_line_one: "Your Health",
    heading_line_two: "Directed By you",
    description:
      "Consult world-class healthcare professionals from anywhere at the touch of a button.",
    link: "/turbomedics",
    title_color: "text-ResolutionBlue",
    heading_line_one_color: "text-black",
    heading_line_two_color: "text-ResolutionBlue",
    border_color: "border-yellow-400",
  },
  {
    image: image_2,
    title: "TURBHAM FARMS",
    heading_line_one: "Grow and thrive ",
    heading_line_two: "with innovation",
    description:
      "We are boldly leading the charge towards a future of abundant farm produce.",
    link: "/farm",
    title_color: "text-DeepGreen",
    heading_line_one_color: "text-black",
    heading_line_two_color: "text-DeepGreen",
    border_color: "border-yellow-400",
  },
  {
    image: image_3,
    title: "LOREM SUPPORT",
    heading_line_one: "Excellence in Business",
    heading_line_two: "Process Outsourcing",
    description:
      "Crafting customer support and energizing back office teams for growth.",
    link: "/logistics",
    title_color: "text-black",
    heading_line_one_color: "text-black",
    heading_line_two_color: "text-black",
    border_color: "border-yellow-400",
  },
  {
    image: image_4,
    title: "TURBHAM TRAVELS AND TOURS",
    heading_line_one: "We make your trip a ",
    heading_line_two: "memorable one",
    description:
      "We take charge of your travel hustles from airline bookings to hotels and airport drop-offs.",
    link: "/travels-and-tour",
    title_color: "text-DeepGreen",
    heading_line_one_color: "text-black",
    heading_line_two_color: "text-DeepGreen",
    border_color: "border-yellow-400",
  },
];

export default ourTechnologyInfos;
