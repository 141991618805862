<template>
  <div class="bg-white sticky top-0 z-50">
    <div
      class="container mx-auto flex flex-row items-center justify-between"
    >
      <router-link to="/" class="relative -left-3 md:-left-6">
        <img
          src="../../assets/images/turbham-logo.gif"
          class="w-28 md:w-52 py-2"
          alt=""
        />
      </router-link>
      <div
        class="hidden md:flex items-center space-x-8 text-lg font-urbanist font-light"
      >
        <router-link to="/mission-and-values">Mission &amp; Values</router-link>
        <OurTechnologiesDropdown />
        <router-link to="/about-us">About Us</router-link>
      </div>

      <!-- Mobile dropdown -->
      <div class="flex flex-row space-x-4 md:hidden">
        <div
          class="flex flex-col space-y-1.5 justify-center items-center cursor-pointer w-fit transition-all duration-500"
          @click="openSidebar"
          v-show="!showSidebar"
        >
          <span class="w-6 md:w-7 h-[2px] bg-black"></span>
          <span class="w-6 md:w-7 h-[2px] bg-black"></span>
          <span class="w-6 md:w-7 h-[2px] bg-black"></span>
        </div>

        <div @click="closeSideBar" class="cursor-pointer" v-show="showSidebar">
          <div
            class="w-6 h-[2px] relative bg-transparent before:absolute before:w-6 before:h-[2px] before:left-0 before:-rotate-45 after:absolute after:w-6 after:h-[2px] after:left-0 after:rotate-45"
            :class="closeIconClass"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import OurTechnologiesDropdown from "@/components/primitives/dropdowns/OurTechnologiesDropdown.vue";
import { inject } from "vue";

const showSidebar = inject("showSidebar");
const openSidebar = inject("openSidebar");
const closeSideBar = inject("closeSidebar");
</script>
<script>
export default {
  name: "Navbar",
};
</script>
