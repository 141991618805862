<template>
  <div class="our_journey_bg">
    <div
      class="container mx-auto flex flex-col items-center space-y-5 md:space-y-0 md:flex-row py-10 md:py-24"
    >
      <div class="w-full md:w-1/2">
        <img src="../../../assets/images/our_journey_img.png" alt="" />
        <!-- <div class="w-[300px] h-[300px]">
          <img
            class="w-full"
            src="../../../assets/images/our_journey_woman.png"
            alt=""
          />
          <img
            class="w-full"
            src="../../../assets/images/our_journey_man.png"
            alt=""
          />
        </div> -->
      </div>
      <div class="w-full md:w-1/2 space-y-5">
        <h3
          class="text-InternationalOrange text-3xl md:text-4xl lg:text-[56px] font-duplicate-sans-medium"
        >
          Our Journey
        </h3>
        <p
          class="md:max-w-4xl text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-normal"
        >
          Amidst her ventures in healthcare, agriculture, BPO services,
          aviation, and finance, Turbham held something close to her heart—a
          non-profit organization. This organization was more than just a
          branch; it was a beacon of hope, a sanctuary for those facing life's
          toughest challenges.
        </p>
        <p
          class="md:max-w-4xl text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-normal"
        >
          As the days passed, Turbham's impact grew, and her influence spread
          far and wide. She believed fervently that everyone, regardless of
          their circumstances, deserved access to basic necessities. It was this
          belief that fueled her relentless pursuit to bridge the gap through
          technology.
        </p>
        <p
          class="md:max-w-4xl text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-normal"
        >
          One by one, lives were touched and transformed. Through her
          cutting-edge solutions, Turbham brought about a revolution that
          rippled through industries, leaving a trail of progress in her wake.
          But she didn't stop there.
        </p>
        <p
          class="md:max-w-4xl text-lg md:text-xl md:leading-[1.8rem] text-DavyGrey font-urbanist font-normal"
        >
          Turbham's dedication to humanitarian efforts was woven into the very
          fabric of her being. She knew that true progress meant lifting others
          up, and so she worked tirelessly to create a brighter future for all.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurJourney",
};
</script>
