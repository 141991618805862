<template>
  <div class="flex items-start space-x-4 text-DavyGrey">
    <div>
      <img :src="drivenMissionInfo.icon" alt="" />
    </div>
    <div class="font-urbanist relative -top-1">
      <span class="font-semibold">
        {{ drivenMissionInfo.title }}
      </span>
      <span class="font-light">
        {{ drivenMissionInfo.description }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive } from "vue";

const props = defineProps(["drivenMissionInfo"]);
</script>
<script>
export default {
  name: "DrivenMissionCard",
};
</script>
