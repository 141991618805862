<template>
  <Header
    title_line_one="Innovative Technology "
    paragraph="As a technology-driven company, we have embraced the latest advancements to create a seamless booking experience. Our user-friendly platforms make ticket management easy, straightforward, and tailored to your individual preferences."
    imageSrc="innovative_technology_img.png"
    text_size="md:text-5xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "InnovativeTechnology",
};
</script>
