<template>
  <div class="bg-black text-white py-10 md:py-24">
    <div class="container mx-auto">
      <div
        class="flex flex-col space-y-4 items-center justify-center text-center mb-16 md:mb-24"
      >
        <h3 class="font-duplicate-sans-medium text-4xl md:text-5xl">
          Welcome to your destination
        </h3>
        <p class="max-w-xl text-xs md:text-sm font-urbanist font-extralight">
          We welcome curious parties who would like to learn more about our
          successes and also entities willing to strategically collaborate with
          us. Turbham is on a mission to redefine business process, shipping,
          what we eat, and how we access healthcare.
        </p>
      </div>
      <hr class="border-t border-t-DavyGrey" />
      <div
        class="flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-16 py-6 md:py-12"
      >
        <div>
          <h4
            class="font-urbanist font-semibold text-xs md:text-sm mb-4 md:mb-12"
          >
            Company
          </h4>
          <div
            class="flex flex-col space-y-2.5 md:space-y-5 font-axiforma-light"
          >
            <router-link to="/about-us" class="text-[10px] md:text-xs hover:border-b w-fit"
              >About us</router-link
            >
            <router-link to="/mission-and-values" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Our Mission and Values</router-link
            >
          </div>
        </div>
        <div>
          <h4
            class="font-urbanist font-semibold text-xs md:text-sm mb-4 md:mb-12"
          >
            Our Technologies
          </h4>
          <div
            class="flex flex-col space-y-2.5 md:space-y-5 font-axiforma-light"
          >
            <router-link to="/turbomedics" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Turbomedics</router-link
            >
            <router-link to="/farm" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Turbham Farms</router-link
            >
            <router-link to="/foundation" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Turbham Foundation</router-link
            >
            <router-link to="/logistics" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Lorem Support</router-link
            >
            <router-link to="/travels-and-tour" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Turbham Travels and Tour</router-link
            >
          </div>
        </div>
        <div>
          <h4
            class="font-urbanist font-semibold text-xs md:text-sm mb-4 md:mb-12"
          >
            Social
          </h4>
          <div
            class="flex flex-col space-y-2.5 md:space-y-5 font-axiforma-light"
          >
            <a href="https://www.linkedin.com/company/turbham/mycompany/verification/" target="_blank" class="text-[10px] md:text-xs hover:border-b w-fit"
              >LinkedIn</a
            >
            <a href="https://x.com/turbham" target="_blank" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Twitter</a
            >
            <a href="https://www.instagram.com/turbham_co_working_space/" target="_blank" class="text-[10px] md:text-xs hover:border-b w-fit"
              >Instagram</a
            >
          </div>
        </div>
        <div>
          <h4
            class="font-urbanist font-semibold text-xs md:text-sm mb-4 md:mb-12"
          >
            Contact Us
          </h4>
          <div
            class="flex flex-col space-y-2.5 md:space-y-5 font-axiforma-light"
          >
            <div to="" class="text-[10px] md:text-xs hover:border-b w-fit">
              <span>Email: </span>
              <a href="mailto:contact@turbham.com">contact@turbham.com</a>
            </div>
            <div to="" class="text-[10px] md:text-xs">
              <!-- Phone: +234 818 887 2426 -->
              <span>Phone: </span>
              <span>&lpar;+234&rpar; 0 818 887 2426</span>
            </div>
            <div to="" class="text-[10px] md:text-xs">
              <!--  Georgia, USA | Kharkiv, Ukraine FHA Estate, Abuja -->
              <span>Address: </span>
              <span>FHA Estate, Guzape, Abuja</span>
            </div>
          </div>
        </div>
      </div>
      <hr class="border-t border-t-DavyGrey" />
      <div
        class="flex flex-row items-center space-x-5 md:space-x-10 py-24 md:pt-32 pb-20"
      >
        <img
          src="../../assets/icons/turbham_footer_logo.svg"
          class="w-24 md:w-32"
          alt=""
        />
        <p class="text-xs font-axiforma-light pt-3.5">
          © Turbham Group of Companies
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const Lawal = "lawal";
</script>

<script>
export default {
  name: "Footer",
};
</script>
