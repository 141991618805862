<template>
  <Header
    Website_name="LOREM SUPPORT"
    title_line_one="Excellence in Business"
    title_line_two="Process Outsourcing"
    paragraph="Explore the seamless synergy of Front Office Solutions, Back Office Solutions, and avant-garde Healthcare and Telemedicine BPO services as we integrate technology and expertise to elevate your business strategies."
    text="Get started today"
    href="https://loremsupport.com/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="lgistics_hero_section_img.png"
    Website_name_color="text-DarkBlueGrey"
    Website_name_bg_color="bg-PaleBlueLily"
    title_line_one_color="text-black"
    title_line_two_color="text-HalloweenOrange"
    text_size="md:text-[56px] md:leading-[3.5rem]"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TurbhamLogisticsHeroSection",
};
</script>
