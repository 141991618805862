<template>
  <Header
    height_and_padding="mb-20"
    title_line_one="Innovating"
    title_line_two="Tomorrow, Today"
    paragraph="We are a dynamic and forward-thinking technology enterprise, offering a versatile array of tailored solutions across multiple sectors."
    imageSrc="home-header-img.svg"
    text_size="md:text-7xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "HomePageHeader",
};
</script>
