<template>
  <Header
    title_line_one="A visionary technology "
    title_line_two="company on a mission to"
    title_line_three="change the world."
    paragraph="Turbham isn't just a company; we are a force of transformation, driven by a deep-seated belief in the power of technology to uplift lives."
    imageSrc="about_us_header_img.png"
    title_line_three_color="text-InternationalOrange"
    text_size="md:text-[54px] md:leading-[3.5rem]"
    italic="italic"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "AboutHeroSection",
};
</script>
