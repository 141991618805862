<template>
  <Header
    Website_name="Turbomedics"
    title_line_one="Your Health"
    title_line_two="Directed By you"
    paragraph="Turbomedics was created to bridge the gap in the healthcare sector, ensuring that advanced medical care is accessible to all."
    text="Get started today"
    href="https://www.turbomedics.com/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="turbomedics_hero_section_img.png"
    Website_name_color="text-ResolutionBlue"
    Website_name_bg_color="bg-Water"
    title_line_one_color="text-black"
    title_line_two_color="text-ResolutionBlue"
    text_size="md:text-7xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TurbomedicsHeroSection",
};
</script>
