<template>
  <div class="step_into_innovation_bg py-24 md:py-56">
    <div
      class="container mx-auto flex justify-center"
    >
      <h2
        class="text-center font-duplicate-sans-medium text-3xl md:text-[44px] md:leading-[3rem] md:max-w-5xl"
      >
        Step into the innovative tapestry of Turbham Technology, where
        LoremSupport takes center stage, blending excellence with technology to
        provide your business an unparalleled platform for success.
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepIntoInnovation",
};
</script>
