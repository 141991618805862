<template>
  <div class="bg-Harp py-10 md:py-44">
    <div class="container mx-auto space-y-5 md:space-y-10">
      <h1
        class="text-center font-duplicate-sans-medium text-3xl md:text-[56px] md:leading-[3.5rem] flex flex-col"
      >
        Our Mission
      </h1>
      <div class="flex flex-col justify-center items-center space-y-2.5 md:space-y-5">
        <p class="md:max-w-[50rem] text-center text-lg md:text-[22px] md:leading-[2.2rem] text-DavyGrey font-urbanist font-light">
          At Turbham Travels and Tours, our mission is to make your travel
          dreams a reality. We believe that ticket management should be a
          breeze, ensuring that your journey starts and ends with comfort and
          convenience.
        </p>
        <p class="md:max-w-[50rem] text-center text-lg md:text-[22px] md:leading-[2.2rem] text-DavyGrey font-urbanist font-light">
          As a Turbham Technology, we leverage innovation to simplify the
          complexities of travel, providing you with a hassle-free and memorable
          experience.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurMission",
};
</script>
