import image_1 from "../assets/images/why_choose_us_img1.svg";
import image_2 from "../assets/images/why_choose_us_img2.svg";
import image_3 from "../assets/images/why_choose_us_img3.svg";

const whyChooseTripleTInfos = [
  {
    image: image_1,
    title: "Classy Experience",
    description:
      "Turbham Travels and Tours is synonymous with class. From the moment you choose us, you embark on a journey that is characterized by elegance and refinement. We understand the importance of style in travel, and we ensure that every detail is curated to perfection.",
  },
  {
    image: image_2,
    title: "Affordable Luxury",
    description:
      "Luxury shouldn’t come at a steep price. Turbham is committed to making your travel experience luxurious without breaking the bank. We offer competitive prices without compromising on the quality of service.",
  },
  {
    image: image_3,
    title: "Hassle-Free Travel",
    description:
      "Bid farewell to travel hassles. Turbham Travel is dedicated to making your travel experience as smooth as possible. Our team of experts is here to handle the nitty-gritty details, leaving you to savor the joy of the journey.",
  },
];

export default whyChooseTripleTInfos;
