<template>
  <Header
    title_line_one="Advanced Software "
    title_line_two="Capabilities"
    paragraph="Our platform boasts advanced software functionalities that streamline workflows, allowing healthcare providers to focus on delivering high-quality care without unnecessary administrative burdens."
    text="Learn more"
    href="https://www.turbomedics.com/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="advance_software_img.png"
    text_size="md:text-5xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "AdvanceSoftware",
};
</script>
