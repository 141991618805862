<template>
  <a
    :href="href"
    target="_blank"
    :class="[
      backgroundColor,
      'btn-inter flex items-center w-fit cursor-pointer',
    ]"
  >
    <span class="btn-content text-[10px] md:text-xs">{{ text }}</span>
    <span class="icon">
      <img
        src="../assets/icons/arrow-right.svg"
        class="w-2 h-2 md:w-2.5 md:h-2.5"
        alt=""
    /></span>
  </a>
</template>

<script>
export default {
  name: "CustomButton",
  props: ["text", "backgroundColor"],
};
</script>

<style scoped>
.btn-inter {
  overflow: hidden;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  font-weight: 600;
  position: relative;
}
.btn-inter .btn-content {
  color: #fff;
  padding: 0 35px 0 0;
  position: relative;
  right: 0;
  transition: right 300ms ease;
}
.btn-inter .icon {
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transition: all 300ms ease;
  transform: translateY(-50%);
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-inter:hover .btn-content {
  right: 100%;
}
.btn-inter:hover .icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 496px) {
  .btn-inter {
    padding: 0.8rem;
  }
  .btn-inter .icon {
    width: 55px;
  }
}
</style>
