<template>
  <div class="what_we_offer_bg relative md:-mt-24">
    <div class="container mx-auto py-16 md:py-32 space-y-10">
      <div class="space-y-8 md:space-y-12 mb-4 md:mb-20">
        <h3
          class="text-white text-3xl md:text-4xl lg:text-[56px] font-duplicate-sans-medium"
        >
          Turbham&apos;s Triad of Excellence
        </h3>
        <p
          class="md:max-w-2xl text-white font-urbanist font-light text-base md:text-xl"
        >
          At Turbham, we have honed a dynamic synergy between process, speed,
          and technology. This triad of excellence defines Turbham, driving us
          towards greater heights of achievement and impact.
        </p>
      </div>
      <div
        class="grid md:grid-cols-2 xl:grid-cols-3 gap-16 md:gap-x-20 md:gap-y-16"
      >
        <div v-for="(whatWeOfferInfo, index) in whatWeOfferInfos" :key="index">
          <WhatWeOfferCard :whatWeOfferInfo="whatWeOfferInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import WhatWeOfferCard from "@/components/primitives/cards/WhatWeOfferCard.vue";
import whatWeOfferInfos from "@/mockdata/whatWeOfferInfos";
</script>

<script>
export default {
  name: "OurOffer",
};
</script>
