<template>
  <div class="bg-AliceBlue py-12 md:py-32">
    <div class="container mx-auto space-y-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div v-for="(paragraph, index) in cards" :key="index">
          <p class="text-DavyGrey">
            {{ paragraph.paragraph }}
          </p>
        </div>
      </div>
      <div class="w-full">
        <img
          src="../../../assets/images/logistic_body_img.png"
          class="w-full rounded-3xl"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const cards = [
  {
    paragraph:
      "Within the Turbham Technology family, LoremSupport curates bespoke call center experiences, transforms customer support into an art form, and forges dynamic back office teams that breathe life into your growth aspirations.",
  },
  {
    paragraph:
      "Acknowledging the unique essence of each business, we embrace a customer-centric philosophy that transcends the ordinary. Our commitment is to craft tailored solutions with precision, adapting and creating to meet the distinctive needs and concerns of our clients.",
  },
  {
    paragraph:
      "At the core of Turbham Technology's ethos lies operational brilliance, ensuring your processes attain the pinnacle of effectiveness, efficiency, and productivity. Our diverse portfolio encompasses Project Management consulting, seamlessly integrated phone, email, and chat solutions, as well as the nuanced artistry of Technical and support services, and Revenue cycle management.",
  },
  {
    paragraph:
      "Lorem Support, assesses your productivity levels and screen the state of your business process towards identifying drawbacks, resource-draining components, and inefficiencies that curtail growthand encumbers business differentiation.",
  },
];
</script>

<script>
export default {
  name: "LogisticsBody",
};
</script>
