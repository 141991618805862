<template>
  <div class="container mx-auto py-16 md:py-32 space-y-10">
    <div class="space-y-8 md:space-y-12 mb-4 md:mb-20">
      <h3
        class="text-3xl md:text-4xl lg:text-[56px] font-duplicate-sans-medium"
      >
        Our Technologies
      </h3>
      <p class="md:max-w-2xl font-urbanist font-light text-base md:text-xl">
        At Turbham, we have honed a dynamic synergy between process, speed, and
        technology. This triad of excellence defines Turbham, driving us towards
        greater heights of achievement and impact.
      </p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
        v-for="(ourTechnologyInfo, index) in ourTechnologyInfos"
        :key="index"
      >
        <OurTechnologyCard :ourTechnologyInfo="ourTechnologyInfo" />
      </div>
    </div>
  </div>
</template>

<script setup>
import OurTechnologyCard from "@/components/primitives/cards/OurTechnologyCard.vue";
import ourTechnologyInfos from "@/mockdata/ourTechnologyInfos";
</script>

<script>
export default {
  name: "OurTechnology",
};
</script>
