<template>
  <div
    class="w-full right-0 bottom-0 fixed top-[100px] overflow-y-scroll xl:hidden z-50 transition-all duration-500"
    :class="`${sideBarClass} ${showSideBar ? 'active' : 'sidebar'}`"
  >
    <div class="container mx-auto md:px-4 py-14">
      <div class="flex flex-col gap-5 mb-10">
        <div class="pb-5 border-0 border-b-[1px] border-b-BluishGrey">
          <h2 class="text-xs md:text-base">
            <router-link to="/mission-and-values"
              >Mission &amp; Values</router-link
            >
          </h2>
        </div>

        <div
          class="pb-5 border-0 border-b-[1px] border-b-BluishGrey overflow-hidden transition-all duration-500"
          :class="showLearn ? 'h-[200px]' : 'h-[37px]'"
        >
          <div class="flex gap-1 md:gap-2 cursor-pointer" @click="toggleLearn">
            <h2 class="text-xs md:text-base flex gap-2 md:gap-4 items-center">
              Our Technologies
            </h2>
            <img
              :src="upArrow"
              alt="up arrow"
              class="transition-all duration-500"
              :class="showLearn && 'rotate-180'"
            />
          </div>

          <div
            class="max-w-[295px] px-2 flex flex-col gap-4"
            :class="showLearn ? 'pt-3' : 'pt-5'"
          >
            <div v-for="(item, index) in learnItems" :key="index">
              <router-link :to="item.link">
                <h2 class="text-[11px] font-medium">{{ item.title }}</h2>
              </router-link>
            </div>
          </div>
        </div>

        <div class="pb-5 border-0 border-b-[1px] border-b-BluishGrey">
          <h2>
            <router-link to="/about-us" class="text-xs md:text-base"
              >About Us</router-link
            >
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sidebarLearnItems } from "@/mockdata/sidebarItems";

export default {
  name: "Sidebar",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const showSideBar = inject("showSidebar");

    const closeSideBar = () => {
      showSideBar.value = false;
    };

    const learnItems = ref(sidebarLearnItems);

    const showProducts = ref(false);
    const showLearn = ref(false);

    const sideBarClass = computed(() =>
      route.path.includes("/blog") ||
      route.path.includes("/partner-with-us") ||
      route.path.includes("/our-mission")
        ? "bg-ResolutionBlue text-white"
        : "bg-white"
    );

    const upArrow = computed(() =>
      route.path.includes("/blog") ||
      route.path.includes("/partner-with-us") ||
      route.path.includes("/our-mission")
        ? require("../../assets/icons/keyboard_arrow_up_light.svg")
        : require("../../assets/icons/keyboard_arrow_up_dark.svg")
    );

    const toggleProducts = () => {
      showProducts.value = !showProducts.value;
    };

    const toggleLearn = () => {
      showLearn.value = !showLearn.value;
    };

    const toggleSubtitles = (product) => {
      product.showSubtitles = !product.showSubtitles;
    };

    router.afterEach(() => {
      closeSideBar();
    });

    return {
      showSideBar,
      upArrow,
      showProducts,
      toggleProducts,
      showLearn,
      toggleLearn,
      sideBarClass,
      learnItems,
      toggleSubtitles,
    };
  },
};
</script>

<style scoped>
.sidebar {
  left: -100%;
  opacity: 0;
}

.active {
  left: 0;
  opacity: 1;
}
</style>
