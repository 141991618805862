<template>
  <div>
    <Navbar />
    <TurbomedicsHeroSection />
    <CuttingEdgeHardwareIntegration />
    <AdvanceSoftware />
    <RobustDataCollectionTools />
    <TelehealthExcellence />
    <PersonalisePatient />
    <SecurityAndCompliance />
    <Footer />
  </div>
</template>

<script setup>
import Navbar from "@/components/connected/Navbar.vue";
import TurbomedicsHeroSection from "@/components/sections/turbomedicsSection/TurbomedicsHeroSection.vue";
import CuttingEdgeHardwareIntegration from "@/components/sections/turbomedicsSection/CuttingEdgeHardwareIntegration.vue";
import AdvanceSoftware from "@/components/sections/turbomedicsSection/AdvanceSoftware.vue";
import RobustDataCollectionTools from "@/components/sections/turbomedicsSection/RobustDataCollectionTools.vue";
import TelehealthExcellence from "@/components/sections/turbomedicsSection/TelehealthExcellence.vue";
import PersonalisePatient from "@/components/sections/turbomedicsSection/PersonalisePatient.vue";
import SecurityAndCompliance from "@/components/sections/turbomedicsSection/SecurityAndCompliance.vue";
import Footer from "@/components/connected/Footer.vue";
</script>

<script>
export default {
  name: "TurbomedicsView",
};
</script>
