import icon_1 from "../assets/icons/process_icon.svg";
import icon_2 from "../assets/icons/speed_icon.svg";
import icon_3 from "../assets/icons/technology_icon.svg";

const whatWeOfferInfos = [
  {
    icon: icon_1,
    title: "Process",
    description:
      "At Turbham, we take pride in our well-defined processes, which streamline operations and lay the foundation for efficiency and quality in all that we do.",
  },
  {
    icon: icon_2,
    title: "Speed",
    description:
      "With a meticulous approach to every facet of our operations, we infuse agility through rapid decision-making and implementation.",
  },
  {
    icon: icon_3,
    title: "Technology",
    description:
      "Our cutting-edge technological solutions serve as the cornerstone of our success, enabling us to innovate, adapt, and lead in every sector we touch.",
  },
];

export default whatWeOfferInfos;
