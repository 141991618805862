<template>
  <Header
    title_line_one="Telehealth Excellence "
    paragraph="Turbomedics harnesses the power of telehealth, connecting patients and providers in real-time for consultations that rival in-person visits. This ensures accessibility to care, especially for those in remote or underserved areas."
    text="Learn more"
    href="https://www.turbomedics.com/"
    backgroundColor="bg-InternationalOrange"
    imageSrc="telehealth_excellence_img.png"
    text_size="md:text-5xl"

  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TelehealthExcellence",
};
</script>
