<template>
  <Header
    Website_name="TURBHAM FARMS"
    title_line_one="Grow and thrive"
    title_line_two="with innovation"
    paragraph="Turbham Farms is dedicated to revolutionizing agriculture through innovation, sustainability, and a deep-rooted commitment to our communities."
    imageSrc="turbham_travel_hero_section_img.png"
    Website_name_color="text-DeepGreen"
    Website_name_bg_color="bg-OffGreen"
    title_line_one_color="text-black"
    title_line_two_color="text-DeepGreen"
     text_size="md:text-7xl"
  />
</template>
<script setup>
import Header from "@/components/primitives/headers/Header.vue";
</script>

<script>
export default {
  name: "TurbhamFarmHeroSection",
};
</script>
